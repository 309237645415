import React from 'react';

const Navbar = () => {

    
    return (
        <div className="bg-green-500 rounded-lg flex justify-center">
            <div className="flex flex-wrap p-2 gap-1">
                <h1 className=" pl-2 self-center text-3xl flex-initial text-white">Wireless Communications Systems Design Laboratory</h1>
                <svg className="flex-initial" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-antenna" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M20 4v8" />
                    <path d="M16 4.5v7" />
                    <path d="M12 5v16" />
                    <path d="M8 5.5v5" />
                    <path d="M4 6v4" />
                    <path d="M20 8h-16" />
                </svg>
            </div>
        </div>
    );
}

export default Navbar;