import React from "react";
import Navbar from "./components/Navbar";
import LinkBudgetModels from "./components/LinkBudgetModels";
import ipn from "./imgs/ipn.jpeg";
import cda from "./imgs/cda.jpeg";
import upiita from "./imgs/upiita.jpeg";
import Modal from "./components/modal/Modal";

function App() {
  return (
    <div className="font-mono m-3">
      <Navbar></Navbar>
      <div className="my-5 flex gap-4">
        <LinkBudgetModels></LinkBudgetModels>
        
        <div className=" basis-1/2 flex flex-col gap-4">
          <div className="border-2 rounded-lg border-green-500">
            <div className="flex justify-center items-center text-white bg-green-500 text-2xl gap-2">
                <p className="self-center">Link budget parameters</p>
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-vector" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M3 3m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
                  <path d="M17 3m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
                  <path d="M17 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
                  <path d="M3 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
                  <path d="M5 7l0 10" />
                  <path d="M19 7l0 10" />
                  <path d="M7 5l10 0" />
                  <path d="M7 19l10 0" />
                </svg>
            </div>
            <table className=" w-full">
              <thead>
                <tr>
                  <th>Symbol</th>
                  <th>Parameter</th>
                  <th>Units</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>PTx</td>
                  <td>Transmit power</td>
                  <td>dB</td>
                </tr>
                <tr>
                  <td>PRx</td>
                  <td>Reception power</td>
                  <td>dB</td>
                </tr>
                <tr>
                  <td>λ</td>
                  <td>Atenuation</td>
                  <td>dB</td>
                </tr>
                <tr>
                  <td>GTx</td>
                  <td>Transmitting antenna gain</td>
                  <td>dB</td>
                </tr>
                <tr>
                  <td>GRx</td>
                  <td>Receiving antenna gain</td>
                  <td>dB</td>
                </tr>
                <tr>
                  <td>α</td>
                  <td>Wavelength</td>
                  <td>m/s</td>
                </tr>
                <tr>
                  <td>Aef</td>
                  <td>Efective area</td>
                  <td>m<sup>2</sup></td>
                </tr>
                <tr>
                  <td>h</td>
                  <td>Height</td>
                  <td>m</td>
                </tr>
              </tbody>
            </table>
          </div>  
        </div>
      </div>
      <footer>
        <div className="bg-green-500 rounded-lg">
          <div className="p-3 flex justify-between">
            <img alt="IPN logo" src={ipn} width="70" height="70" className=" rounded-lg"></img>
            <img alt="CDA logo" src={cda} width="140" height="70" className=" rounded-lg"></img>
            <img alt="UPIITA logo" src={upiita} width="140" height="70" className=" rounded-lg"></img>
          </div>
          <div className="p-3">
            <Modal ></Modal>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
