import React, { useState } from 'react'

const WalfishIkegami231 = () => {

    const [distanciaTotal, setDistanciaTotal] = useState(0);
    const [alturaEstacionBase, setAlturaEstacionBase] = useState(0);
    const [alturaEstacionMovil, setAlturaEstacionMovil] = useState(0);
    const [anguloCalleEstacion, setAnguloCalleEstacion] = useState(0);
    const [alturaEdificio, setAlturaEdificios] = useState([0]);
    const [distanciaEdificios, setDistanciaEdificios] = useState([]);
    const [frecuenciaOnda, setFrecuenciaOnda] = useState(0);
    const [lbLos, setLbLos] = useState(0);
    const [lb, setLb] = useState(0);
    const [anchoCalleAntena, setAnchoCalleAntena] = useState(0);

    const handleAddAltura = () => {
        const aux = [...alturaEdificio,0];
        if(aux.length > 1){
            const aux2 = [...distanciaEdificios,0];
            setDistanciaEdificios(aux2);
        }
        setAlturaEdificios(aux);
    }

    const handleChangeAltura = (e, i) => {
        const aux = [...alturaEdificio];
        aux[i] = parseFloat(e.target.value);
        setAlturaEdificios(aux);
    }

    const handleChangeDistancia = (e, i) => {
        const aux = [...distanciaEdificios];
        aux[i] = parseFloat(e.target.value);
        setDistanciaEdificios(aux);
    }

    const handleDelete = (i) => {
        const aux = [...alturaEdificio];
        aux.splice(i,1);
        const aux2 = [...distanciaEdificios];
        if(i === 0) {
            aux2.splice(i,1);
        }else{
            aux2.splice((i-1),1);
        }
        setDistanciaEdificios(aux2);
        setAlturaEdificios(aux);
    }

    const calcularPerdidas = () => {

        let alturaPromedio = 0;

        for(let i = 0; i < alturaEdificio.length; ++i){
            alturaPromedio += alturaEdificio[i];
        }

        alturaPromedio /= alturaEdificio.length;

        let separacionPromedio = 0;

        for(let i = 0; i < distanciaEdificios.length; ++i){
            separacionPromedio += distanciaEdificios[i];
        }

        separacionPromedio /= distanciaEdificios.length;

        let kf = -4 + 1.5 * ((frecuenciaOnda / 925) -1);

        let kd = 0;

        if(alturaEstacionBase > alturaPromedio){
            kd = 18;
        }else{
            kd = 18 - 15 * ((alturaEstacionBase - alturaPromedio) / alturaPromedio);
        }

        let ka = 0;

        if(alturaEstacionBase <= alturaPromedio && distanciaTotal >= 0.5){
            ka = 54 - 0.8 * (alturaEstacionBase - alturaPromedio);
        }else if(alturaEstacionBase <= alturaPromedio && distanciaTotal < 0.5){
            ka = 54 - 0.8 * (alturaEstacionBase - alturaPromedio) * (distanciaTotal / 0.5);
        }else if(alturaEstacionBase > alturaPromedio){
            ka = 54;
        }

        let lbsh = 0;

        if(alturaEstacionBase > alturaPromedio){
            lbsh = -18 * Math.log10(1 + alturaEstacionBase - alturaPromedio);
        }
        
        let lori = 0;

        if(anguloCalleEstacion >= 0 && anguloCalleEstacion < 35){
            lori = -10 + 0.35 * anguloCalleEstacion;
        }else if(anguloCalleEstacion >= 35 && anguloCalleEstacion < 55){
            lori = 2.5 + 0.0755 * (anguloCalleEstacion - 35);
        }else if(anguloCalleEstacion >= 55 && anguloCalleEstacion < 90){
            lori = 4 - 0.1144 * (anguloCalleEstacion - 55);
        }

        let lmsd = lbsh + ka + kd * Math.log10(distanciaTotal) + kf * Math.log10(frecuenciaOnda) - 9 * Math.log10(separacionPromedio);
        
        let lrts = -16.9 - 10 * Math.log10(anchoCalleAntena) + 10 * Math.log10(frecuenciaOnda) + 20 * Math.log10(alturaPromedio - alturaEstacionMovil) + lori;

        let lo = 32.45 + 20 * Math.log10(frecuenciaOnda) + 20 * Math.log10(distanciaTotal);

        if(lrts + lmsd > 0){
            setLb(lo + lrts + lmsd);
        }else{
            setLb(lo);
        }

        setLbLos(42.6 + (26 * Math.log10(distanciaTotal)) + (20 * Math.log10(frecuenciaOnda)));
    }

    return (
        <div>
            <div>
                <p>The extended model known as Cost231 Walfisch Ikegami is especially used in urban environments. This particular model is a statistical and deterministic combination of how communications behave in the frequency ranges of (800-2000)MHz. According to international telecommunications standards, all mobile services generally operate within the (800-2100)MHz range.</p>
            </div>
            <div className='flex flex-col items-center'>
                <p className='text-2xl my-3'>Parameters</p>
                <div>
                    <div>
                        <div className='grid grid-cols-2 gap-4'>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Total distance</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setDistanciaTotal(parseFloat(e.target.value));}} value={distanciaTotal} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>km</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Base station height: </label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setAlturaEstacionBase(parseFloat(e.target.value));}} value={alturaEstacionBase} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>m</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Mobile station height: </label>
                                <div className='p-2 flex gap-2 justify-center self-center'>
                                    <input onChange={(e) => {setAlturaEstacionMovil(parseFloat(e.target.value));}} value={alturaEstacionMovil} className='border rounded-lg self-center' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>m</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Angle between street and mobile station: </label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setAnguloCalleEstacion(parseFloat(e.target.value));}} value={anguloCalleEstacion} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>Sexagesimal</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2 my-4'>
                                <label>Wave frequency: </label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setFrecuenciaOnda(parseFloat(e.target.value));}} value={frecuenciaOnda} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>MHz</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2 my-4'>
                                <label>Receiving antenna street width: </label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setAnchoCalleAntena(parseFloat(e.target.value));}} value={anchoCalleAntena} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>m</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <button className=' w-full flex justify-center my-4 bg-green-500 rounded-full hover:bg-green-700' onClick={() => handleAddAltura()}>Add building</button>
                        {alturaEdificio.length === 1 ? (
                            <div className='flex justify-center items-center'>
                                {alturaEdificio.map((altura, i) => {
                                    return(
                                        <div className='flex flex-col border rounded-lg border-gray-500 p-2 w-1/2'>
                                            <label>Height of building number {i + 1}: </label>
                                            <div className='p-2 flex gap-2 justify-center'>
                                                <input onChange={(e) => {handleChangeAltura(e, i);}} value={altura} className='border rounded-lg' type='number'></input>
                                                <select className='border rounded-lg'>
                                                    <option>m</option>
                                                </select>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <div>
                                <div className='grid grid-cols-2 gap-4'>
                                    {alturaEdificio.map((altura, i) => {
                                        return(
                                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                                <label>Height of building  {i + 1}: </label>
                                                <div className='p-2 flex gap-2 justify-center'>
                                                    <input onChange={(e) => {handleChangeAltura(e, i);}} value={altura} className='border rounded-lg' type='number'></input>
                                                    <select className='border rounded-lg'>
                                                        <option>m</option>
                                                    </select>
                                                    <button onClick={() => handleDelete(i)} className=' bg-red-600 rounded-lg hover:bg-red-800'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-square-rounded-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                            <path d="M10 10l4 4m0 -4l-4 4" />
                                                            <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {distanciaEdificios.length === 1 ? (
                                    <div className='flex justify-center items-center my-4'>
                                        {distanciaEdificios.map((distancia, i) => {
                                            return(
                                                <div className='flex flex-col border rounded-lg border-gray-500 p-2 w-1/2'>
                                                    <label>Distance between buildings {i + 1}-{i + 2}:</label>
                                                    <div className='p-2 flex gap-2 justify-center'>
                                                        <input onChange={(e) => {handleChangeDistancia(e, i);}} value={distancia} className='border rounded-lg' type='number'></input>
                                                        <select className='border rounded-lg'>
                                                            <option>m</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className='grid grid-cols-2 gap-4 my-4'>
                                        {distanciaEdificios.map((distancia, i) => {
                                            return(
                                                <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                                    <label>Distance between buildings {i + 1}-{i + 2}:</label>
                                                    <div className='p-2 flex gap-2 justify-center'>
                                                        <input onChange={(e) => {handleChangeDistancia(e, i);}} value={distancia} className='border rounded-lg' type='number'></input>
                                                        <select className='border rounded-lg'>
                                                            <option>m</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <button className=' w-full flex justify-center my-4 bg-green-500 rounded-full hover:bg-green-700' onClick={() => calcularPerdidas()}>Calculate</button>
                <p className='text-2xl my-3'>Results</p>
                <div className='grid grid-cols-2 gap-4'>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2 my-4'>
                        <label>Loss with LOS: </label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={lbLos} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>dB</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2 my-4'>
                        <label>Loss without LOS: </label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={lb} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>dB</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WalfishIkegami231;