import React, { useState } from 'react'

const SatelitelITU = () => {

    const [orbitAltitude, setOrbitAltitude] = useState(0);
    const [elevationAngle, setElevationAngle] = useState(0);
    const [antennaGain, setAntennaGain] = useState(0);
    const [groundStationAntennaPointingLoss, setGroundStationAntennaPointingLoss] = useState(0);
    const [spacecraftAntennaPointingLoss, setSpacecraftAntennaPointingLoss] = useState(0);
    const [spacecraftAntennaGain, setSpacecraftAntennaGain] = useState(0);
    const [spacecraftAntennaGainDown, setSpacecraftAntennaGainDown] = useState(0);
    const [spacecraftAntennaPointingLossD, setSpacecraftAntennaPointingLossD] = useState(0);
    const [groundStationAntennaPointingLossD, setGroundStationAntennaPointingLossD] = useState(0);
    const [groundStationAntennaGainD, setGroundStationAntenneGainD] = useState(0);
    const [antennaInputPower, setAntennaInputPower] = useState(0);
    const [spacecraftOperationTemperature, setSpacecraftOperationTemperature] = useState(0);
    const [spacecraftAntennaInputPower, setSpacecraftAntennaInputPower] = useState(0);
    const [atmosphericLosses, setAtmosphericLosses] = useState(0);
    const [frequency, setFrequency] = useState(0);

    const [fsl, setFsl] = useState(0);
    const [groundStationEIRP, setGroundStationEIRP] = useState(0);
    const [spacecraftEIRP, setSpacecraftEIRP] = useState(0);
    const [pr, setPr] = useState(0);
    const [cN, setCN] = useState(0);
    const [cND, setCND] = useState(0);


    const calcularPerdidas = () => {

        setFsl(32.5 + 20 * Math.log10(orbitAltitude) + 20 * Math.log10(frequency) );

        setGroundStationEIRP(10 * Math.log10(antennaInputPower) + antennaGain);

        alert(groundStationEIRP);
        alert(fsl);
        alert(antennaGain);

        setPr(groundStationEIRP - fsl + antennaGain - 2.15);

        console.log(groundStationEIRP);
        console.log(fsl);
        console.log(atmosphericLosses);

        setCN(groundStationEIRP - fsl + (16/1000) - atmosphericLosses - (1.380649 * Math.pow(10,-23)));

        setSpacecraftEIRP(10 * Math.log10(spacecraftAntennaInputPower) + spacecraftAntennaGain);

        setCND(spacecraftEIRP - fsl + (2.2/1000) - atmosphericLosses - (1.380649 * Math.pow(10,-23)));
    }

    return (
        <div>
            <div>
                <p>The link budget is the process by which the physical performance values ​​of a communications system are estimated. This process includes in its equations all the components of the system, as well as its technical specifications, link losses and present noise.</p>
            </div>
            <div className='flex flex-col items-center'>
                <p className='text-2xl my-3'>Parameters</p>
                <div>
                    <div>
                        <div className='grid grid-cols-2 gap-4'>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Orbit altitude</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setOrbitAltitude(parseFloat(e.target.value));}} value={orbitAltitude} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>km</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Elevation angle</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setElevationAngle(parseFloat(e.target.value));}} value={elevationAngle} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>°</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Antenna gain</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setAntennaGain(parseFloat(e.target.value));}} value={antennaGain} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>dBi</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Frequency</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setFrequency(parseFloat(e.target.value));}} value={frequency} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>MHz</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Antenna Input Power</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setAntennaInputPower(parseFloat(e.target.value));}} value={antennaInputPower} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>dBw</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Ground Station Antenna Pointing Loss</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setGroundStationAntennaPointingLoss(parseFloat(e.target.value));}} value={groundStationAntennaPointingLoss} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>dB</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Spacecraft Operation Temperature</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setSpacecraftOperationTemperature(parseFloat(e.target.value));}} value={spacecraftOperationTemperature} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>K</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Spacecraft Antenna Pointing Loss</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setSpacecraftAntennaPointingLoss(parseFloat(e.target.value));}} value={spacecraftAntennaPointingLoss} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>dB</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Spacecraft Antenna Gain</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setSpacecraftAntennaGain(parseFloat(e.target.value));}} value={spacecraftAntennaGain} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>dBi</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Spacecraft Antenna Input Power</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setSpacecraftAntennaInputPower(parseFloat(e.target.value));}} value={spacecraftAntennaInputPower} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>dBw</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Downlink Spacecraft Antenna Gain</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setSpacecraftAntennaGainDown(parseFloat(e.target.value));}} value={spacecraftAntennaGainDown} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>dBi</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Donwlink Spacecraft Antenna Pointing Loss</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setSpacecraftAntennaPointingLossD(parseFloat(e.target.value));}} value={spacecraftAntennaPointingLossD} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>dB</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Downlink Ground Station Antenna Pointing Loss</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setGroundStationAntennaPointingLossD(parseFloat(e.target.value));}} value={groundStationAntennaPointingLossD} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>dB</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Downlink Ground Station Antenna Gain</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setGroundStationAntenneGainD(parseFloat(e.target.value));}} value={groundStationAntennaGainD} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>dBi</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Atmospheric Losses</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setAtmosphericLosses(parseFloat(e.target.value));}} value={atmosphericLosses} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>dB</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className=' w-full flex justify-center my-4 bg-green-500 rounded-full hover:bg-green-700' onClick={() => calcularPerdidas()}>Calculate</button>
                <p className='text-2xl my-3'>Results</p>
                <div className='grid grid-cols-2 gap-4'>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                        <label>Uplink Path Loss: </label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={fsl} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>dB</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                        <label>Ground Station EIRP:</label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={groundStationEIRP} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>dBw</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                        <label>Reception power</label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={pr} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>dB</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                        <label>Uplink Carrier to Noise</label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={cN} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>W</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                        <label>Spacecraft EIRP</label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={spacecraftEIRP} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>dBw</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                        <label>Downlink Carrier to noise</label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={cND} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>W</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SatelitelITU;