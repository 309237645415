import React, {useState} from "react";
import "./Modal.css";

const Modal = () => {

    const [modal, setModal] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
    }

    return(
        <div className=" align-middle bg-green-800 rounded-full hover:bg-green-600">
            <button
            onClick={toggleModal}
            className=" btn-modal p-2">Was it usefull? Cite here</button>
            {modal && (
            <div className="modal">
                <div className="overlay">
                </div>
                <div className="modal-content">
                    <h2>Cite here</h2>
                    <br></br>
                    <div className="flex flex-row">
                        <div className=" px-5">
                            <h2 className=" font-bold">Bibtex</h2>
                            <p>{"@ARTICLE{Casillas-AviNa2023176,"}</p>
                            <p>{"\tauthor = {Casillas-Aviña, G.E. and López-Balcázar, C.A. and Yáñez-Casas, G.A. and Hernández-Gómez, J.J. and Arao-Quiroz, J.M. and Mata-Rivera, M.F.},"}</p>
                            <p>{"\ttitle = {Development of a Web-Based Calculator to Simulate Link Budget for Mobile Communications Systems at Urban Settlements},"}</p>
                            <p>{"\tyear = {2023},"}</p>
                            <p>{"\tjournal = {Communications in Computer and Information Science},"}</p>
                            <p>{"\tvolume = {1906 CCIS},"}</p>
                            <p>{"\tpages = {176 – 198},"}</p>
                            <p>{"\tdoi = {10.1007/978-3-031-45316-8_13},"}</p>
                            <p>{"\ttype = {Conference paper},"}</p>
                            <p>{"\tpublication_stage = {Final},"}</p>
                            <p>{"\tsource = {Scopus},"}</p>
                            <p>{"\t}"}</p>
                            <br></br> 
                            <h2 className=" font-bold">Cite</h2>
                            <p>{"Casillas-Aviña G.E., López-Balcázar C.A., Yáñez-Casas G.A., Hernández-Gómez J.J., Arao-Quiroz J.M., Mata-Rivera M.F. Development of a Web-Based Calculator to Simulate Link Budget for Mobile Communications Systems at Urban Settlements (2023). Communications in Computer and Information Science, 1906 CCIS, pp. 176 - 198, DOI: 10.1007/978-3-031-45316-8_13"}</p>
                            <br></br>
                        </div>
                        <div className=" px-5">
                            <h2 className=" font-bold">Bibtex</h2>
                            <p>{"@ARTICLE{RosasPalacios2024radiofrecuency,"}</p>
                            <p>{"\tauthor = {A. Rosas-Palacios and G. E. Casillas-Aviña and G. A. Yáñez-Casas and C. A. López-Balcázar and J. J. Hernández-Gómez},"}</p>
                            <p>{"\ttitle = {Radiofrequency and optical satellite link budgeting calculator},"}</p>
                            <p>{"\tyear = {2024},"}</p>
                            <p>{"\tjournal = {Journal of Physics: Conference Series},"}</p>
                            <p>{"\tvolume = {},"}</p>
                            <p>{"\tpages = {},"}</p>
                            <p>{"\ttype = {Conference paper},"}</p>
                            <p>{"\tpublication_stage = {In Press},"}</p>
                            <p>{"\tnote = {In Press},"}</p>
                            <p>{"\t}"}</p>
                            <br></br>
                            <h2 className=" font-bold">Cite</h2>
                            <p>{"Rosas-Palacios A., Casillas-Aviña G.E., Yáñez-Casas G.A., López-Balcázar C.A., Hernández-Gómez J.J. Radiofrequency and optical satellite link budgeting calculator (2024). Journal of Physics: Conference Series, In Press."}</p>
                        </div>
                    </div>
                    <button className=" border-gray-500 close-modal bg-green-500"
                onClick={toggleModal}>Close</button>
                </div>
            </div>)}
            
        </div>
    );
}

export default Modal;