import React, { useState } from 'react'
import WalfishIkegami231 from './WalfishIkegami231';
import SatelitelITU from './SateliteITU';
import SateliteOptical from './SateliteOptical';

const LinkBudgetModels = () => {

    const [selected,setSelected] = useState("231WI");

    return (
        <div className="basis-1/2 border-2 rounded-lg border-green-500">
            <div className="flex justify-center items-center text-white bg-green-500 text-2xl gap-2">
                <p className="self-center">Link budget models</p>
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-share" width="20" height="20" viewBox="0 0 24 24" stroke-width="2.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                    <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                    <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                    <path d="M8.7 10.7l6.6 -3.4" />
                    <path d="M8.7 13.3l6.6 3.4" />
                </svg>
            </div>
            <div className='flex flex-col m-3 gap-3'>
                <select value={selected} onChange={(e) => setSelected(e.target.value)} className='w-full rounded-lg border'>
                    <option value="231WI">231 Walfisch Ikegami</option>
                    <option value="SatRF">Satelite Radio Frequency Link</option>
                    <option value="SatOP">Optical Satelite Link</option>
                </select>
                {selected === "231WI" ? <WalfishIkegami231></WalfishIkegami231> : ""}
                {selected === "SatRF" ?  <SatelitelITU></SatelitelITU>: ""}
                {selected === "SatOP" ? <SateliteOptical></SateliteOptical>:""}
            </div>
        </div>
    );
}

export default LinkBudgetModels;