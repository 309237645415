import React, { useState } from 'react'

const SateliteOptical = () => {

    const [mediaAntennaGain, setMediaAntennaGain] = useState(0);
    const [lossesBybuildingOfDevice, setLossesBybuildingOfDevice] = useState(0);
    const [beamDivergenceAngle, setBeamDivergenceAngle] = useState(0);
    const [beamRadialDesviation, setBeamRadialDesviation] = useState(0);
    const [waveLength, setWaveLength] = useState(0);
    const [orbitalHeight, setOrbitalHeight] = useState(0);
    const seaLevel = 6371;
    const [opticalEarthStationHeight, setOpticalEarthStationHeight] = useState(0);
    const [linkElevationAngle, setLinkElevationAngle] = useState(0);
    const [cumberTransmisionValue, setCumberTransmisionValue] = useState(0);
    const [lossThorughtAtmosphericScintillation, setLossThorughtAtmosphericScintillation] = useState(0);
    const [intensityRecolectionArea, setIntensityRecolectionArea] = useState(0);
    const [lensApertureLoss, setLensApertureLoss] = useState(0);

    const [gainOfTheAntennaTx, setGainOfTheAntennaTx] = useState(0);
    const [avergaeLossDueToDynamicBeamMiss, setAverageLossDueToDynamicBeamMiss] = useState(0);
    const [lossInFreeSpaceByLinkDistance, setLossInFreeSpaceByLinkDistance] = useState(0);
    const [sumOfAtmosphericAttenuationEffectsCommunication, setSumOfAtmosphericAttenuationEffectsCommunication] = useState(0);
    const [gainOfTheReceiverAntenna, setGainOfTheReceiverAntenna] = useState(0);
    const [opticalPowercollectedByTheOpeningAngle, setOpticalPowercollectedByTheOpeningAngle] = useState(0);
    const [transmissionPowerReceiver, setTransmissionPowerReceiver] = useState(0);
    const [distance, setDistance] = useState(0);

    const calcularPerdidas = () => {

        let conversionNm = Math.pow(10,-9);
        let conversionKm = Math.pow(10,3);

        let openingAngle = (Math.sqrt(Math.log(2) / 2)) * beamDivergenceAngle;

        setGainOfTheAntennaTx(10 * Math.log10((16 * Math.log(2))/(Math.pow(openingAngle, 2))));
        alert(openingAngle);

        let b = (Math.pow(openingAngle, 2))/(4 * Math.log(2) * Math.pow(beamRadialDesviation,2));
        alert(b);
        setAverageLossDueToDynamicBeamMiss(10 * Math.log10((b)/(b+1)));

        let l = Math.sqrt(Math.pow(seaLevel + opticalEarthStationHeight,2) * Math.pow(Math.sin(linkElevationAngle * Math.PI / 180),2) + 2 * (orbitalHeight - opticalEarthStationHeight)*(seaLevel + opticalEarthStationHeight) + Math.pow((orbitalHeight - opticalEarthStationHeight),2)) - (seaLevel + opticalEarthStationHeight) * Math.sin(linkElevationAngle * Math.PI / 180);

        setDistance(l);
        setLossInFreeSpaceByLinkDistance(10 * Math.log10((Math.pow(waveLength * conversionNm, 2))/(16 * Math.pow((Math.PI * l * conversionKm),2))));

        setSumOfAtmosphericAttenuationEffectsCommunication(10 * Math.log10(Math.pow(cumberTransmisionValue,(1/Math.sin(linkElevationAngle * Math.PI / 180)))));

        setGainOfTheReceiverAntenna(10 * Math.log10((4 * Math.PI * intensityRecolectionArea)/(Math.pow(waveLength * conversionNm, 2))));

        setOpticalPowercollectedByTheOpeningAngle(mediaAntennaGain + gainOfTheAntennaTx + lossInFreeSpaceByLinkDistance + gainOfTheReceiverAntenna);

        setTransmissionPowerReceiver(mediaAntennaGain + lossesBybuildingOfDevice + gainOfTheAntennaTx + avergaeLossDueToDynamicBeamMiss + lossInFreeSpaceByLinkDistance + sumOfAtmosphericAttenuationEffectsCommunication + lossThorughtAtmosphericScintillation + gainOfTheReceiverAntenna + lensApertureLoss);
    }

    return (
        <div>
            <div>
                <p>The link budget is the process by which the physical performance values ​​of a communications system are estimated. This process includes in its equations all the components of the system, as well as its technical specifications, link losses and present noise.</p>
            </div>
            <div className='flex flex-col items-center'>
                <p className='text-2xl my-3'>Parameters</p>
                <div>
                    <div>
                        <div className='grid grid-cols-2 gap-4'>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Media Antenna Gain</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setMediaAntennaGain(parseFloat(e.target.value));}} value={mediaAntennaGain} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>dBm</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Losses by Building Of Device</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setLossesBybuildingOfDevice(parseFloat(e.target.value));}} value={lossesBybuildingOfDevice} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>dB</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Beam Divergence Angle</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setBeamDivergenceAngle(parseFloat(e.target.value));}} value={beamDivergenceAngle} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>°</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Beam Radial Desviation</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setBeamRadialDesviation(parseFloat(e.target.value));}} value={beamRadialDesviation} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option></option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Wave Lenght</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setWaveLength(parseFloat(e.target.value));}} value={waveLength} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>nm</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Orbital Height</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setOrbitalHeight(parseFloat(e.target.value));}} value={orbitalHeight} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>km</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Altitude of optical Station Over Sea Level</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setOpticalEarthStationHeight(parseFloat(e.target.value));}} value={opticalEarthStationHeight} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>km</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                    <label>Link Elevation Angle</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setLinkElevationAngle(parseFloat(e.target.value));}} value={linkElevationAngle} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>°</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Cumber Transmision Value</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setCumberTransmisionValue(parseFloat(e.target.value));}} value={cumberTransmisionValue} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option></option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Loss through atmospheric scintillation</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setLossThorughtAtmosphericScintillation(parseFloat(e.target.value));}} value={lossThorughtAtmosphericScintillation} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option></option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Intensity Recolection Area</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setIntensityRecolectionArea(parseFloat(e.target.value));}} value={intensityRecolectionArea} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option>m<sup>2</sup></option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-col border rounded-lg border-gray-500 p-2'>
                                <label>Loss due to Lens Aperture</label>
                                <div className='p-2 flex gap-2 justify-center'>
                                    <input onChange={(e) => {setLensApertureLoss(parseFloat(e.target.value));}} value={lensApertureLoss} className='border rounded-lg' type='number'></input>
                                    <select className='border rounded-lg'>
                                        <option></option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className=' w-full flex justify-center my-4 bg-green-500 rounded-full hover:bg-green-700' onClick={() => calcularPerdidas()}>Calculate</button>
                <p className='text-2xl my-3'>Results</p>
                <div className='grid grid-cols-2 gap-4'>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2 my-4'>
                        <label>Gain of the antenna of the transmitter: </label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={gainOfTheAntennaTx} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>dB</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2 my-4'>
                        <label>Link distance: </label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={distance} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>km</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2 my-4'>
                        <label>Average loss due to dynamic beam miss-pointing: </label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={avergaeLossDueToDynamicBeamMiss} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>dB</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2 my-4'>
                        <label>Loss in free space by link distance: </label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={lossInFreeSpaceByLinkDistance} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>dB</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2 my-4'>
                        <label>Sum of atmospheric attenuation effects communication: </label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={sumOfAtmosphericAttenuationEffectsCommunication} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>dB</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2 my-4'>
                        <label>Gain of the receiver antenna: </label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={gainOfTheReceiverAntenna} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>dB</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2 my-4'>
                        <label>Optical power colected by the opening angel of the reciver: </label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={opticalPowercollectedByTheOpeningAngle} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>dB</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col border rounded-lg border-gray-500 p-2 my-4'>
                        <label>Transmission power of the receiver: </label>
                        <div className='p-2 flex gap-2 justify-center'>
                            <input readOnly value={transmissionPowerReceiver} className='border rounded-lg' type='number'></input>
                            <select className='border rounded-lg'>
                                <option>dBm</option>
                            </select>
                        </div>
                    </div>                  
                </div>
            </div>
        </div>
    );
}

export default SateliteOptical;